.body-contract{ 
    main {
        overflow: hidden !important;
    }
    #app-toolbar,
    .app-bar__customer {
        display: none !important;
    }
    header {
        display: none !important;
    }
    .MuiDrawer-root {
        display: none !important;
    }
}

.contract {
    border: 1px solid #3d3d3d;
    // direction: rtl;
    // p,ul {
    //     direction: rtl;
    // }
    padding: 10px;
    width: 1240px !important;
    margin: auto !important;
    margin-top: 20px !important;
    // background-color: #eee;
    .header__left {
        text-align: left;
    }
    table {
        width: 100%;
        td {
            width: 25%;
            padding: 5px;
            border-bottom: 1px solid #eee;
            font-size: 12px;
            div {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
    .cost-contract {
        th {
            text-align: right;
            background: #ececec;
            padding: 10px 5px;
        }
    }
    h4.section-header {
        font-weight: bold;
        padding: 10px 10px;
        margin: 0px;
        font-size: 22px;
        background-color: #efefef;
    }
    .contract__sign {
        max-width: 300px;
        max-height: 300px;
        // height: 300px;
    }
    .contract__text {
        text-align: justify;
    }
    .text_intro {
        b {
            margin: 0 5px;
            display: inline-block;
        }
        .textEn {
            font-weight: bold;
        }
    }
    -webkit-print-color-adjust: exact !important;
            print-color-adjust:exact !important;

    @media print {
        border: 0px solid #eee;
        margin-top: 0px !important;
        width: auto !important;
        font-size: 12px;
        .print-area {
            display: none;
        }
        #pageborder {
            position:fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid #eee;
          }
        h4.section-header {
            
            background-color: #eee;
            padding: 5px;
        }
    }
    .qr-code {
        margin-left: 20px;
        border: 2px solid #ddd;
        padding: 5px;
        border-radius: 5px;
        padding-bottom: 1px;
    }
    .d-flex {
        position: relative;
        display: flex;
        align-items: center;
        h2 {
            margin: 0;
        }
        .contract__title {
            h2 {
                margin-bottom: 8px;
            }
            b {
                margin: 0 4px ;
            }
        }
    }
    &__english {
        direction: ltr;
        .cost-contract {
            th {
                text-align: left;
                background: #ececec;
                padding: 10px 5px;
            }
        }
        .qr-code {
            margin-left: 5px;
            margin-right: 15px;
        }
        .header__left{
            text-align: right;
        }
        .hide-en {
            display: none;
        }
    }
}