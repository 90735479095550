.bookings {
    .status{
        &__DRAFT {
            background-color: aliceblue;
        }
        &__WAITING_PAYMENT_ACCEPT {
            background-color: rgb(209, 171, 0);
        }
        &__WAITING_PAYMENT {
            background-color: orange;
        }
        &__WAITING_MEETING,
        &__NEW {
            background-color: rgb(92, 92, 253);
            color: #fff;
        }
        &__DONE {
            background-color: green;
            color: #fff;
        }
        &__CANCELED {
            background-color: #ff1616;
            color: #fff;
        }
        &__REJECTED {
            background-color: #ff1616;
            color: #fff;
        }
    }
}

.booking {
    .gridItem {
        padding: 15px 10px;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        &__value {
            margin-top: 5px;
            font-size: 1.1em;
        }
    }
    .MuiCardHeader-root{
        background: #949494;
        color: #fff;
    }
    .MuiCardHeader-title {
        font-size: 1.2em;
        // padding-bottom: 10px;
        font-weight: bold;
        // border-bottom: 1px solid  #eee;
    }
    b {
        margin-left: 5px;
    }
    .item__setting {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
    }
}