@import '../../assets/mixin.scss';

// .invoices {
    .tag_div {
        @include tag_container;
        &.tag__done {
            @include get_back_color('success')
        }
        &.tag__waitForApprove {
            @include get_back_color('waiting')
        }
        &.tag__waitPayment {
            @include get_back_color('waitingDark')
        }
        &.tag__canceled {
            @include get_back_color('error')
        }
        &.tag__partPaymentDone {
            @include get_back_color('waiting')
        }
    }
// }