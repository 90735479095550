.App {
  text-align: center;
  font-family: 'IranSans';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  background-color: #f7fcfe !important;
}

body.zoom90 {
  zoom: 90%;
}

body.zoom80 {
  zoom: 80%;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.muiDatableMaster {
  // background-color: red;
  box-shadow: unset !important;
}
.muiDatableMaster .MuiToolbar-root .MuiTextField-root{
  padding: 5px;
}
.ltr{
  direction: ltr;
}
.main-ltr {
  direction: ltr;
}

::-webkit-scrollbar {
  width: 0px;
}

.muiDatableMaster{
  .MuiToolbar-gutters{
    background: #f6fcfe !important;
    padding: 0px !important;
  }
  div > table {
    border: 1px solid #eee;
  }
  // div[class^="MUIDataTable-responsiveBase"]{
  //   padding: 4px;
  //   border-radius: 10px;
  //   background-color: #eee;
  // }
  .MuiTableCell-root {
    padding: 8px;
    background-color: #fff;
    // border-bottom: 1px solid #3d3d3d;
  }
  th{
    button {
      font-weight: bold;
    }
  }
  h6{
    font-size: 1.5em;
    font-weight: bolder;
  }
}

.custom-calendar {
  input {
    width: 100% !important;
  }
}
.rmdp-container {
  width: 100%;
}
.link{
  &:hover{
    cursor: pointer;
  }
}
.MuiAlert-standardInfo {
  color: #fff !important;
  background-color: #0b7bfa !important;
}
.MuiCard-root {
  margin: 2px !important;
}
.card_0 {
  .MuiCardMedia-root {
    color: #16b1ff;
  }
}.card_1 {
  .MuiCardMedia-root {
    color: #feb300;
  }
}.card_2 {
  .MuiCardMedia-root {
    color: #56c900;
  }
}.card_3 {
  .MuiCardMedia-root {
    color: #9255fd;
  }
}

.rmdp-shadow.custom-calendar {
  z-index: 101 !important;
}

.tbl_info {
  border: 1px solid #eee !important;
  box-shadow: unset !important;
  .MuiTableCell-root.MuiTableCell-head{
    z-index: 99;
  }
}

.fab-icon {
  position: fixed !important;
  left: 50px;
  bottom: 70px;
  z-index: 100;
  .help-text {
    display: none;
  }
  &__action {
    transition: width 100ms linear 0ms !important;
    &:hover {
      width: 150px;
      border-radius: 20px;
      .help-text {
        display: inline-block;
      }
    }
  }
}

.main-ltr {
  .fab-icon {
    left: unset;
    right: 50px;
  }
  table {
    .icon-go {
      rotate: 180deg;
    }
  }
}

.filter-bar {
  position: relative;
  border: 1px solid #eee;
  margin-top: 10px;

  // .MuiFormControl-marginNormal {
  //   margin-top: 8px !important;
  // }
  // .MuiTextField-root {
  //   margin-top: 8px !important;
  // }
  &__container {
    padding: 10px;
  }
  &__btn {
    display: flex;
    align-items:center;
  }
}

.button-primary {
  &:not(.Mui-disabled)>span {
    color: #fff;
  }
}
