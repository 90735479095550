.inbox {
    .MuiCardContent-root {
        padding-top: 0px;
    }
    &__message {
        height: 60vh;
        overflow-y: scroll;
        ul {
            padding: 0;
            list-style-type: none;
            li {
                margin-bottom: 2px;
                span {
                    padding: 10px;
                    background-color: #eee;
                    border-radius: 5px;
                    display: inline-block;
                }
                .footer-message {
                    margin-top: 5px;
                    border-top: 1px solid #eee;
                    font-size: 10px;
                    padding-top: 5px;
                    color: #3d3d3d;
                    display: flex;
                    justify-content: flex-end;
                    i {
                        display: inline-block;
                        margin: 0 4px;
                        font-style: normal;
                        direction: ltr;
                    }
                }
            }
        }
    }
    &__button {
        padding-top: 10px;
        position: relative;
    }
    .MuiTextField-root {
        margin: 0px !important;
        padding-bottom: 0px !important;
        .MuiInputBase-root {
            height: 100%;
        }
    }
    .btn-attachment {
    }
}