.dialog-confirm-step {
    hr {
        border: 1px solid #eee;
    }
    .message_active {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .fieldset-normal {
        margin-top: 12px;
    }
    .radio-options{
        fieldset {
            width: 100%;
        }
        .radio__option {
            border-bottom: 1px solid #eee;
            width: 100%;
            padding: 10px 8px !important;
            margin-bottom: 1px;
            &:hover {
                background-color: #eee;
            }
        }
        .radio__description {
            font-size: 12px;
            color: #6f6f6f;
        }
    }
}

.todo-list {
    .actions {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;
    }
    .reminder {
        display: flex;
        align-items: center;
    }
    .row--disabled {
        opacity: .6;
    }
    .row--default {
        .MuiTableCell-root {
            font-weight: bold;
        }
    }
}

.table {
    width: 100%;
    // background-color: #eee;
    // border: 2px solid #eee;
    tr td{
        font-weight: bold;
        padding: 8px;
        border: 1px solid #d8d8d8;
    }
}

.date-picker-ms {
    .MuiInputBase-root{
        flex-direction: row-reverse;
        padding-right: 14px;
        padding-left: unset;
    }
}