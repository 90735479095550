.file-upload {
    background-color: red;
    .img {
        img {
            width: 60px;
            height: 50px;
        }
    }
    .file-uploaded {
        margin-top: 8px;
        border: 1px solid #eee;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        direction: ltr;
        background-color: #eee;
    }
    .detail {
        flex-grow: 1;
        text-align: left;
    }
    .progress {
        direction: rtl;
    }
    .MuiLinearProgress-bar {
        direction: ltr;
    }
}