.stepWrap {
    min-width: 100%;
    overflow-x: scroll;
    // background-color: red;
    .MuiStepper-root {
      padding: 10px;
      padding-bottom: 0px !important;
      padding-top: 0px !important;
      overflow-y: hidden;
    }
    .MuiStep-alternativeLabel {
      padding-top: 15px;
    }
    .MuiStepConnector-alternativeLabel {
      padding-top: 15px;
    }
  
    .step-disabled {
      opacity: .5;
      &:hover{
        cursor: not-allowed !important;
      }
    }
    &>div{
      @media (max-width: 768px){
        padding: 0;
        min-width: 700px;
      }
    }
  }