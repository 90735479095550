@import '../../assets/mixin.scss';

.status_button {
    &.status__accepted {
        @include get_back_color('success')
    }
    &.status__rejected {
        @include get_back_color('error')
    }
    &.status__waitUser {
        @include get_back_color('waiting')
    }
    &.status__waitReview {
        @include get_back_color('waitingDark')
    }
    &.status__inReview {
        background-color: #80ed99;
        color: #fff;
    }
}
.requests {
    .tag_div {
        @include tag_container;
        &.status__accepted {
            @include get_back_color('success')
        }
        &.status__contractDone {
            @include get_back_color('success')
        }
        &.status__rejected {
            @include get_back_color('error')
        }
        &.status__contractCanceled {
            @include get_back_color('errorDark')
        }
        &.status__waitUser {
            @include get_back_color('waiting')
        }
        &.status__waitReview {
            @include get_back_color('waitingDark')
        }
        &.status__inReview {
            background-color: #80ed99;
            color: #fff;
        }
    }

    .current_step {
        @include tag_container;
        
        &.STEP_INFORMATION {
            background-color: #4cc9f0;
            color: #fff;
        }
        &.STEP_CONTRACT {
            background-color: #3a0ca3;
            color: #fff;
        }
        &.STEP_PAYMENT {
            background-color: #81b29a;
            color: #fff;
        }
        &.STEP_DOCUMENT {
            background-color: #06d6a0;
            color: #fff;
        }
        &.STEP_END {
            background-color: #57cc99;
            color: #fff;
        }
    }
}