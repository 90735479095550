@mixin tag_container {
    background-color: #eee;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    min-width: 75px;
    text-align: center;
}

@mixin get_back_color($color) {
    @if $color == success {
        background-color: #06d6a0;
        color: #fff;
    }
    @if $color == error {
        background-color: #d62828;
        color: #fff;
    }
    @if $color == errorDark {
        background-color: #991e1e;
        color: #fff;
    }
    @if $color == waiting {
        background-color: #ffd166;
        color: #fff;
    }
    @if $color == waitingDark {
        background-color: #fb8b24;
        color: #fff;
    }
}